import { SHOW_PROGRAMSA, UPDATE_REVISION_PROGRAMSA, ERROR_EDIT_REVISION, SUCCESSFULL_EDIT_REVISION, SUCCESSFULL_APPROVE_PROJECT, ERROR_APPROVE_PROJECT } from "constants/actionTypes";
import { LOAD_FORM_PILOT_GROUP } from "constants/actionTypes";
import { SHOW_PROGRAMSA_PROGRESS } from "constants/actionTypes";
import { LOADING_PROGRAMSA } from "constants/actionTypes";
import { RESET_PAGINATED_PROGRAMSA_LIST } from "constants/actionTypes";
import { PAGINATED_PROGRAMSA_LIST } from "constants/actionTypes";
import { SHOW_PILOT_GROUP_LIST } from "constants/actionTypes";
import { NEW_PILOT_GROUP } from "constants/actionTypes";
import { LOAD_FORM_PROGRAMSA } from "constants/actionTypes";
import { HIDE_REVISION_ALERT, ERROR_SEND_REVISION_PROJECT, SUCCESSFULL_SEND_REVISION_PROJECT, SA_PROJECT_LIST } from "constants/actionTypes";

const initialState = { 
  show_programsa_progress: {
    requirements:{
      mision:"0",
      generate:"0",
      graduate:"0",
      facilitate:"0",
      support:"0",
      pilot:"",
    },
    corrections:{},
    total_progress:"0",
  },
  programsa:{
    mision1: "",
    mision3: "",
    mision4: "",  
    generate_groups1: "",
    generate_groups2: "",
    generate_groups3: [""],
    generate_groups4: "",
    generate_groups5: "",
    generate_groups6: "",
    generate_groups7: "",   
    graduate1: "",
    graduate2: "",
    graduate3: "",
    graduate4: "",
    graduate5: [],   
    rule1: "",
    rule2: "",
    rule3: "",
    rule4: "",
    rule5: "",
    rule6: "",
    rule7: "",
    rule8: "",  
    rule9: "",
    rule10: "",
    support1: "",
    support2: "",
    support3: "",
    support4: "",
    state: "",
    statusmision: "",
    statusgraduate: "",
    statusgenerate_groups: "",
    statusrule: "",
    statussupport: "",
    code: "",
    student:{
        id: 1,
        username: "",
        language: "",
        language_grader: [],
        first_name: "",
        last_name: "",
        country: "",
        city: "",
        whatsapp: "",
        roles: [],
        groupes: [],
        studentgroup: {
          id: "",
          group:{
            id: "",
            name: "",
            start_date: "",
            final_date: "",
            graduation_date: "",
            number_students: "",
            modality: "",
            program: "",
            interweave_local: "",
            embassador:{
              id: "",
              username: "",
              language: "",
              language_grader: [],
              first_name: "",
              last_name: "",
              country: "",
              city: "",
              whatsapp: "",
            }
          }
        }
    },
  },
  pilot_group:{
    groups: {"g-220": true}
  },
  show_pilot_group:{
    pilotgroups:[],
    graduates:[]
  },
  sa_project_list: [],
  editRevisionError: false,
  editRevisionSuccessfull: false,
  approveProjectError: false,
  approveProjectSuccessfull: false,
  sendRevisionProjectError: false,
  sendRevisionProjectSuccessfull: false,
  pages_total: "1",
  paginated_programsa_list:[],
  approved_paginated_programsa_list:[],
  programsa_pagination_params:{
    keyword: "",
    page:  1,
    page_size: 10,
    order_column: "updated_at",
    order_direction: "DESC",
  },
  approved_programsa_pagination_params:{
    keyword: "",
    page:  1,
    page_size: 10,
    order_column: "approval_date",
    order_direction: "DESC",
  },
  loading: true,
}



export const programsaReducer = (state = initialState, action) => {
    switch (action.type) {
      case SHOW_PROGRAMSA:
        return Object.assign({}, state, {
          programsa: action.payload
        });

      case UPDATE_REVISION_PROGRAMSA:
        return Object.assign({}, state, {
          programsa: Object.assign(state.programsa, {
            [action.payload.name]: action.payload.value
          })
        });

      case LOAD_FORM_PROGRAMSA:   
        return Object.assign({}, state, {
          data: action.data
        });

      case ERROR_EDIT_REVISION:
        return Object.assign({}, state, {
          editRevisionError: true
        });

      case SUCCESSFULL_EDIT_REVISION:
        return Object.assign({}, state, {
          editRevisionSuccessfull: true
        });

      case ERROR_APPROVE_PROJECT:
        return Object.assign({}, state, {
          approveProjectError: true
        });

      case SUCCESSFULL_APPROVE_PROJECT:
        return Object.assign({}, state, {
          approveProjectSuccessfull: true
        });
      
      case ERROR_SEND_REVISION_PROJECT:
        return Object.assign({}, state, {
          sendRevisionProjectError: true
        });

      case SUCCESSFULL_SEND_REVISION_PROJECT: 
        return Object.assign({}, state, {
          sendRevisionProjectSuccessfull: true
        });
      
      case SA_PROJECT_LIST: 
        return Object.assign({}, state, {
          sa_project_list: action.payload,
          loading: false,
        });

      case HIDE_REVISION_ALERT: 
        return Object.assign({}, state, {
          editRevisionError: false,
          editRevisionSuccessfull: false,
          approveProjectError: false,
          approveProjectSuccessfull: false,
          sendRevisionProjectError: false,
          sendRevisionProjectSuccessfull: false
        });

      case NEW_PILOT_GROUP:
        return Object.assign({}, state, {
          new_pilot_group: action.data
        });      
      case LOAD_FORM_PILOT_GROUP:
        var data =  {};
        var options = {};
        if(action.data.length != undefined){
          action.data.forEach(element => {
            options["g-" + element.id] = true;
          });
          data.groups = options;
        }
        return Object.assign({}, state, {
          pilot_group: data
        });

      case SHOW_PILOT_GROUP_LIST:
        return Object.assign({}, state, {
          show_pilot_group: action.data
        });

      case SHOW_PROGRAMSA_PROGRESS:
        return Object.assign({}, state, {
          show_programsa_progress: action.payload,
        });
      case PAGINATED_PROGRAMSA_LIST:
        return Object.assign({}, state, {
          paginated_programsa_list: action.payload.list,
          pages_total: action.payload.pages_total,
          loading: false
        });
        case RESET_PAGINATED_PROGRAMSA_LIST:
          return Object.assign({}, state, {
            paginated_programsa_list:[],
            pages_total: "1",
          });
      case LOADING_PROGRAMSA: 
        return Object.assign({}, state, {
          loading: true
        });
    }

   
    return state;
}

