import { LOAD_FORM_SECTION } from "constants/actionTypes";
import { SHOW_SECTION } from "constants/actionTypes";
import { LOAD_FORM_TUTORIAL } from "constants/actionTypes";
import { LIST_AVAILABLE_TUTORIAL } from "constants/actionTypes";
import { SET_TUTORIAL_KEYWORD } from "constants/actionTypes";
import { CLEAN_SHOW_TUTORIAL } from "constants/actionTypes";
import { LIST_SECTION_LANGUAGE } from "constants/actionTypes";
import { LIST_AVAILABLE_TUTORIAL_KEY } from "constants/actionTypes";
import { LIST_AVAILABLE_SECTION } from "constants/actionTypes";
import { SHOW_TUTORIAL } from "constants/actionTypes";
import { LIST_TUTORIAL } from "constants/actionTypes";
import { EDIT_SECTION } from "constants/actionTypes";
import { NEW_SECTION } from "constants/actionTypes";
import { LIST_SECTION } from "constants/actionTypes";

const initialState = {
  section_list: [],
  section_language_list: [],
  tutorial_list: [],
  available_section_list: [],
  available_tutorial_list: [],
  available_tutorial_list_key: [],
  tutorial_keyword: "",
  new_section: {
    name: "",
    description: "",
    language: "en",
  },
  show_section: {
    id: "",
    name: "",
    description: "",
  },
  new_tutorial: {
    name: "",
    description: "",
    language: "en",
    section: "-1",
    device: "desktop",
  },
  show_tutorial: {
    name: "",
    description: "",
    language: "en",
    section: "-1"
  },
  loading: true
}

export const tutorialReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_SECTION:
      return Object.assign({}, state, {
        section_list: action.payload,
        loading: false
      });
    case LIST_SECTION_LANGUAGE:
      return Object.assign({}, state, {
        section_language_list: action.payload,
      });
    case NEW_SECTION:
      return Object.assign({}, state, {
        show_section: action.payload,
        loading: false
      });
    case LOAD_FORM_SECTION:
      return Object.assign({}, state, {
        data: action.data,
      });
    case EDIT_SECTION:
      return Object.assign({}, state, {
        edit_section: action.payload,
      });
    case SHOW_SECTION:
      return Object.assign({}, state, {
        show_section: action.payload,
      });
    case LIST_TUTORIAL:
      return Object.assign({}, state, {
        tutorial_list: action.payload,
        loading: false
      });
    case SHOW_TUTORIAL:
      return Object.assign({}, state, {
        show_tutorial: action.payload,
      });
    case LOAD_FORM_TUTORIAL:
      var tutorial = action.data;
      var roles = {};
      tutorial.roles.forEach(element => {
        roles[element] = true;
      });
      tutorial.section = tutorial.section.id;
      tutorial.roles = roles;
      return Object.assign({}, state, {
        data: tutorial,
      });
    case LIST_AVAILABLE_TUTORIAL:
      return Object.assign({}, state, {
        available_tutorial_list: action.payload,
      });
    case LIST_AVAILABLE_TUTORIAL_KEY:
      return Object.assign({}, state, {
        available_tutorial_list_key: action.payload,
      });
    case LIST_AVAILABLE_SECTION:
      return Object.assign({}, state, {
        available_section_list: action.payload,
      });
    case SET_TUTORIAL_KEYWORD:
      return Object.assign({}, state, {
        tutorial_keyword: action.payload,
      });
    case CLEAN_SHOW_TUTORIAL:
      return Object.assign({}, state, {
        available_tutorial_list_key: [],
        show_tutorial: {
          name: "",
          description: "",
          language: "en",
          section: "-1"
        },
      });
  }
  return state;
}

