import { LIST_CATEGORY } from "constants/actionTypes";
import { UPDATE_CATEGORYLIST_SELECT } from "constants/actionTypes";
import { SHOW_SUBCATEGORY } from "constants/actionTypes";
import { EDIT_CATEGORY } from "constants/actionTypes";
import { LIST_SUBCATEGORY } from "constants/actionTypes";
import { NEW_RESOURCE } from "constants/actionTypes";
import { EDIT_RESOURCE } from "constants/actionTypes";
import { UPDATE_SUBCATEGORYLIST_SELECT } from "constants/actionTypes";
import { LIST_RESOURCE_SUBCATEGORY } from "constants/actionTypes";
import { LIST_LANGUAGE_CATEGORY } from "constants/actionTypes";
import { LOAD_FORM_RESOURCE } from "constants/actionTypes";
import { SHOW_RESOURCE } from "constants/actionTypes";
import { LIST_RESOURCE } from "constants/actionTypes";
import { EDIT_SUBCATEGORY } from "constants/actionTypes";
import { LOAD_FORM_SUBCATEGORY } from "constants/actionTypes";
import { NEW_SUBCATEGORY } from "constants/actionTypes";
import { LOAD_FORM_CATEGORY } from "constants/actionTypes";
import { SHOW_CATEGORY } from "constants/actionTypes";
import { NEW_CATEGORY } from "constants/actionTypes";

const initialState = { 
  list_category: [],
  list_language_category: [],
  list_subcategory: [],
  list_resource: [],
  list_resource_subcategory: [],
  category_selected: "-1",
  subcategory_selected: "-1",
  new_resource:{
    name:"",
    description:"",
    category: "-1",
    subcategory: "-1",
    version: "",
    position: "0",
    video: "state.not",
    url:"",
  },
  show_resource:{
    name:"",
    video:"",
    description:"",
    resourcecategory:{
      name:"",
      category:{
        name:"",
        language:"",
      }  
    }
  },
  new_category:{
    name:"",
    description:"",
    language: "en",
    position: "0",
  },
  show_category:{
    id:"",
    name:"",
    description:"",
    language: "en",
  },
  new_subcategory:{
    category: "-1",
    name:"",
    description:"",
    position:"0",
  },
  show_subcategory:{
    id:"",
    category: "",
    name:"",
    description:"",
  },
  resource_selector : {
    language: "ALL",
    category: "ALL",
    subcategory: "ALL"
  },
  category_selector : {
    language: "ALL",
  },
  loading:true 
}

export const resourceReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIST_CATEGORY:
          return Object.assign({}, state, {
            list_category: action.payload,
            loading: false
          });
        case LIST_LANGUAGE_CATEGORY:
          return Object.assign({}, state, {
            list_language_category: action.payload,
            loading: false
          });
        case NEW_CATEGORY:
          return Object.assign({}, state, {
            show_category: action.payload,
          });
        case SHOW_CATEGORY:
          return Object.assign({}, state, {
            show_category: action.payload,
          });
        case EDIT_CATEGORY:
          return Object.assign({}, state, {
            edit_category: action.payload,
          });
        case LOAD_FORM_CATEGORY:
          var category = action.data;
          var roles = {};
          category.roles.forEach(element => {
            roles[element] = true;
          });
          category.roles = roles;
          return Object.assign({}, state, {
            data: category
          });
        case UPDATE_CATEGORYLIST_SELECT:
          return Object.assign({}, state, {
            category_selected: action.payload,
          });
        case NEW_SUBCATEGORY:
          return Object.assign({}, state, {
            show_subcategory: action.payload,
          });
        case SHOW_SUBCATEGORY:
          return Object.assign({}, state, {
            show_subcategory: action.payload,
          });
        case EDIT_SUBCATEGORY:
          return Object.assign({}, state, {
            edit_subcategory: action.payload,
          });
        case LOAD_FORM_SUBCATEGORY:
          var objectClon = Object.assign({} , action.data)
          var subcategory = objectClon;
          var category = "";
          category=subcategory.category.id;
          subcategory.category = category;
          return Object.assign({}, state, {
            data: subcategory,
          });
          case UPDATE_SUBCATEGORYLIST_SELECT:
            return Object.assign({}, state, {
              subcategory_selected: action.payload,
            });
        case LIST_SUBCATEGORY:
          return Object.assign({}, state, {
            list_subcategory: action.payload,
            loading: false
          });
        case LIST_RESOURCE:
          return Object.assign({}, state, {
            list_resource: action.payload,
            loading: false
          });
        case NEW_RESOURCE:
          return Object.assign({}, state, {
            show_resource: action.payload,
          });
        case SHOW_RESOURCE:
          return Object.assign({}, state, {
            show_resource: action.payload,
          });
        case EDIT_RESOURCE:
          return Object.assign({}, state, {
            edit_resource: action.payload,
          });
        case LOAD_FORM_RESOURCE:
          var objectResourceClon = Object.assign({} , action.data)
          var resource = objectResourceClon;
          var category = "";
          var subcategory = "";
          //category=resource.resourcecategory.category.id;
          //subcategory = resource.resourcecategory.id;
          //resource.category=category;
          //resource.subcategory=subcategory;
          return Object.assign({}, state, {
            data_resource: action.data
          });
        case LIST_RESOURCE_SUBCATEGORY:
        return Object.assign({}, state, {
          list_resource_subcategory: action.payload,
          loading: false
        });
}
    return state;
}