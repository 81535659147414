import { LIST_COURSE } from "constants/actionTypes";
import { NEW_UNIT } from "constants/actionTypes";
import { NEW_PRINCIPLE } from "constants/actionTypes";
import { LOAD_FORM_COURSE } from "constants/actionTypes";
import { UPDATE_UNIT_TREE } from "constants/actionTypes";
import { UPDATE_UNITLIST_SELECT } from "constants/actionTypes";
import { CONTENT_LIST } from "constants/actionTypes";
import { CHANGE_NEXT_PRINCIPLE } from "constants/actionTypes";
import { LOAD_FORM_PRINCIPLE } from "constants/actionTypes";
import { COURSE_VIEW_STUDENT } from "constants/actionTypes";
import { CHANGE_UNIT_NAME_WARNING } from "constants/actionTypes";
import { UNIT_LIST_PUBLISHED } from "constants/actionTypes";
import { ANSWER_LIST } from "constants/actionTypes";
import { STOP_LIST } from "constants/actionTypes";
import { PROGRESS_STATE_LIST } from "constants/actionTypes";
import { SHOW_PROGRESS_PENDING_CORRECTION } from "constants/actionTypes";
import { RESET_WAITING_PRINCIPLE_LOAD } from "constants/actionTypes";
import { LIST_PAYMENT } from "constants/actionTypes";
import { SUCCESSFUL_TRANSFER_STUDENTS } from "constants/actionTypes";
import { THEREIS_COURSE_PUBLISHED } from "constants/actionTypes";
import { COURSE_PUBLISHED } from "constants/actionTypes";
import { WAITING_STUDENTS_TRANSFER } from "constants/actionTypes";
import { SHOW_PAYMENT } from "constants/actionTypes";
import { COURSE_PAYMENT } from "constants/actionTypes";
import { WAITING_PRINCIPLE_LOAD } from "constants/actionTypes";
import { PROGRESS_SHOW_NEXT_PENDING } from "constants/actionTypes";
import { STOP_STATE_LIST } from "constants/actionTypes";
import { SHOW_ANSWER } from "constants/actionTypes";
import { CHANGE_SUCCESSFUL } from "constants/actionTypes";
import { CHANGE_PRINCIPLE_NAME_WARNING } from "constants/actionTypes";
import { COURSE_VIEW_EDITOR } from "constants/actionTypes";
import { UPDATE_UNIT_ID } from "constants/actionTypes";
import { UPDATE_PRINCIPLE_ID } from "constants/actionTypes";
import { UNIT_LIST } from "constants/actionTypes";
import { SHOW_PRINCIPLE } from "constants/actionTypes";
import { SHOW_UNIT } from "constants/actionTypes";
import { SHOW_COURSE } from "constants/actionTypes";
import { NEW_COURSE } from "constants/actionTypes";
import { SUCCESSFULL_NEW_COURSE } from "constants/actionTypes";
import { NEW_TEST } from "constants/actionTypes";
import { LOAD_FORM_TEST } from "constants/actionTypes";

const initialState = {
  list_course: [],
  progress_state_list: [],
  progress_next_pending: [],
  show_progress_pending_correction: [],
  waiting_students_transfer: false,
  successful_transfer_students: false,
  thereis_course_published:false,
  course_published: [],
  list_payment: [],
  show_payment: {
    student:{
      first_name:"",
      last_name:""
    },
    course:{
      name:""
    },
    price:"",
    create_at:"2023-06-06"
  },
  course_payment: "",
  change_principle_name_warning: false,
  change_unit_name_warning: false,
  principle_stop_warning: false,
  waiting_principle_load: false,
  course_view: "editor",
  new_course: {
    name: "",
    description: "",
    state: "state.draft",
    requirements: "requirement.mbs",
    language: "en",
    price: "0",
  },
  show_course: {
    name: "",
    description: "",
    state: "state.draft",
    language: "en",
    roles: [],
    requirements: "requirement.mbs",
  },
  new_unit: {
    name: "",
    description: "",
    state: "state.draft",
    content: "",
    course_id: 0,
    position: 0,
  },
  show_unit: {
    name: "",
    description: "",
    state: "",    content: "",
  },
  new_principle: {
    name: "",
    unitList: "-1",
    state: "state.draft",
    unit_id: 0,
    unit: "-1",
    description: "",
    content: "",
    answerType: "option.checkbox",
    finalAnswer: "",
    position: 0,
    type: 0,
  },
  principle_id: "",
  unit_id: "",
  show_principle: {
    name: "",
    unit: "-1",
    state: "state.draft",
    stopState: "state.inactive",
    unit_id: 0,
    description: "",
    content: "",
    answerType: "option.checkbox",
    finalAnswer: "",
    position: 0,
    type: 0,
    unit: [
    ]
  },
  unit_tree: {
    rootId: '1',
    items: {
      '1': {
        id: '1',
        children: ['1-1'],
        hasChildren: true,
        isExpanded: true,
        isChildrenLoading: false,
        isUnit: true,
        data: {
          title: 'root',
        },
      },
      '1-1': {
        id: '1-1',
        children: [],
        hasChildren: true,
        isExpanded: true,
        isChildrenLoading: true,
        isUnit: true,
        data: {
          title: '',
        },
      }
    }
  },
  content_tree: {
    rootId: '1',
    items: {
      '1': {
        id: '1',
        children: ['1-1'],
        hasChildren: true,
        isExpanded: true,
        isChildrenLoading: false,
        isUnit: true,
        data: {
          title: 'root',
        },
      },
      '1-1': {
        id: '1-1',
        children: [],
        hasChildren: true,
        isExpanded: true,
        isChildrenLoading: true,
        isUnit: true,
        data: {
          title: '',
        },
      }
    }
  },
  unit_list: [],
  unit_list_selected: "-1",
  answer_type_selected: "option.checkbox",
  stop_list: {
    principle: {
      id: ""
    },
  },
  stop_state_list: [],
  new_test: {
    principle_id: 0,
    description: "",
    selected: "",
    position: "",
    answer_list: { income: "", array: ["", "", "", ""] },
  },
  learner_view: "false",
  next_principle: undefined,
  answer_list: [],
  show_answer: {
    question: "",
    answer: "",
    comment: "",
  },

  loading: true
}

export const courseReducer = (state = initialState, action) => {
  switch (action.type) {

    case SUCCESSFULL_NEW_COURSE:
      return Object.assign({}, state, {
        show_course: action.payload
      });

    case LIST_COURSE:
      return Object.assign({}, state, {
        list_course: action.payload,
        loading: false
      });

    case NEW_COURSE:
      return Object.assign({}, state, {
        show_course: action.payload,
      });

    case SHOW_COURSE:
      return Object.assign({}, state, {
        show_course: action.payload,
      });

    case NEW_UNIT:
      return Object.assign({}, state, {
        new_unit: action.payload,
      });

    case SHOW_UNIT:
      return Object.assign({}, state, {
        show_unit: action.payload,
      });

    case NEW_PRINCIPLE:
      return Object.assign({}, state, {
        new_principle: action.payload,
      });

    case SHOW_PRINCIPLE:
      return Object.assign({}, state, {
        show_principle: action.payload,
      });
    case UPDATE_PRINCIPLE_ID:
      return Object.assign({}, state, {
        principle_id: action.payload,
      });
    case UPDATE_UNIT_ID:
      return Object.assign({}, state, {
        unit_id: action.payload,
      });
    case UPDATE_UNITLIST_SELECT:
      return Object.assign({}, state, {
        unit_list_selected: action.payload,
      });

    case UNIT_LIST:
      let contentChildren = [];
      let contentArray = {};
      if (action.payload.units.length > 0) {

        action.payload.units.map((prop, key) => {

          let number = "1-" + prop.id + "-0";
          contentChildren.push(number);


          Object.assign(contentArray, {
            [number]: {
              id: number,
              children: [],
              hasChildren: true,
              isExpanded: true,
              isChildrenLoading: false,
              isUnit: true,
              api_id: prop.id,
              data: {
                title: prop.name,
              }
            }
          });


          if (prop.principles.length > 0) {

            prop.principles.map((princ, key) => {
              let principle_number = "1-" + prop.id + "-" + princ.id;
              contentChildren.push(principle_number);
              Object.assign(contentArray, {
                [principle_number]: {
                  id: principle_number,
                  children: [],
                  hasChildren: false,
                  isExpanded: false,
                  isChildrenLoading: false,
                  isUnit: false,
                  api_id: princ.id,
                  data: {
                    title: princ.name,
                  }
                }
              });

            });
          }
        });

      }

      let headerTree = {
        '1': {
          id: '1',
          children: ['1-1'],
          hasChildren: true,
          isExpanded: true,
          isChildrenLoading: false,
          isUnit: true,
          data: {
            title: 'root',
          },
        },
        '1-1': {
          id: '1-1',
          children: contentChildren,
          hasChildren: true,
          isExpanded: true,
          isChildrenLoading: true,
          isUnit: false,
          data: {
            title: action.payload.course.name,
          },
        }
      };
      var items = { ...headerTree, ...contentArray }
      const dataTree = { rootId: '1', items };

      return Object.assign({}, state, {
        unit_list: action.payload.units,
        unit_tree: dataTree,
        learner_view: action.payload.learner_view
      });

    case UNIT_LIST_PUBLISHED:
      let contentPublishedChildren = [];
      let contentPublishedArray = {};
      if (action.payload.units.length > 0) {

        action.payload.units.map((prop, key) => {

          let number = "1-" + prop.id + "-0";
          contentPublishedChildren.push(number);

          Object.assign(contentPublishedArray, {
            [number]: {
              id: number,
              children: [],
              hasChildren: true,
              isExpanded: true,
              isChildrenLoading: false,
              isUnit: true,
              api_id: prop.id,
              data: {
                title: prop.name,
              }
            }
          });
          if (prop.principles.length > 0) {

            prop.principles.map((princ, key) => {
              if (princ.state === "state.published") {
                let principle_number = "1-" + prop.id + "-" + princ.id;
                contentPublishedChildren.push(principle_number);
                Object.assign(contentPublishedArray, {
                  [principle_number]: {
                    id: principle_number,
                    children: [],
                    hasChildren: false,
                    isExpanded: false,
                    isChildrenLoading: false,
                    isUnit: false,
                    api_id: princ.id,
                    data: {
                      title: princ.name,
                    }
                  }
                });
              }
            });
          }
        });

      }

      let headerPubTree = {
        '1': {
          id: '1',
          children: ['1-1'],
          hasChildren: true,
          isExpanded: true,
          isChildrenLoading: false,
          isUnit: true,
          data: {
            title: 'root',
          },
        },
        '1-1': {
          id: '1-1',
          children: contentPublishedChildren,
          hasChildren: true,
          isExpanded: true,
          isChildrenLoading: true,
          isUnit: false,
          data: {
            title: action.payload.course.name,
          },
        }
      };
      items = { ...headerPubTree, ...contentPublishedArray }
      const dataPubTree = { rootId: '1', items };

      return Object.assign({}, state, {
        unit_list: action.payload.units,
        unit_tree: dataPubTree,
        learner_view: action.payload.learner_view
      });









    case CONTENT_LIST:
      let unitArray = {};
      let principleArray = {};
      let unitChildren = [];
      if (action.payload.units.length > 0) {

        unitChildren = action.payload.units.map((prop, key) => {

          let principleChildren = [];

          if (prop.principles.length > 0) {

            principleChildren = prop.principles.map((princ, key) => {
              let principle_number = "1-" + prop.id + "-" + princ.id;
              Object.assign(principleArray, {
                [principle_number]: {
                  id: principle_number,
                  children: [],
                  hasChildren: false,
                  isExpanded: false,
                  isChildrenLoading: false,
                  isUnit: false,
                  api_id: princ.id,
                  data: {
                    title: princ.name,
                  }
                }
              });
              return principle_number;
            });
          }


          let number = "1-" + prop.id;
          Object.assign(unitArray, {
            [number]: {
              id: number,
              children: principleChildren,
              hasChildren: true,
              isExpanded: true,
              isChildrenLoading: false,
              isUnit: true,
              api_id: prop.id,
              data: {
                title: prop.name,
              }
            }
          });
          return number;
        });

      }

      headerTree = {
        '1': {
          id: '1',
          children: unitChildren,
          hasChildren: true,
          isExpanded: true,
          isChildrenLoading: false,
          isUnit: true,
          data: {
            title: 'root',
          },
        },
      };
      //const items = {...headerTree, ...unitArray, ...principleArray}
      //const dataTree = {rootId: '1', items};

      return Object.assign({}, state, {
        unit_list: action.payload.units,
        content_tree: dataTree
      });

    case UPDATE_UNIT_TREE:
      return Object.assign({}, state, {
        unit_tree: action.payload,
      });

    case LOAD_FORM_COURSE:
      var course = action.data;
      var roles = {};
      //if(course.roles.length != undefined){
      course.roles.forEach(element => {
        roles[element] = true;
      });
      course.roles = roles;
      //}

      return Object.assign({}, state, {
        data: course,
      });

    case LOAD_FORM_PRINCIPLE:
      var principle = action.data;
      principle.unit = principle.unit.id
      return Object.assign({}, state, {
        data: principle,
      });

    case NEW_TEST:
      return Object.assign({}, state, {
        new_test: action.payload,
      });

    case CHANGE_NEXT_PRINCIPLE:
      return Object.assign({}, state, {
        next_principle: action.payload,
      });

    case LOAD_FORM_TEST:
      var programmbs = action.data;
      return Object.assign({}, state, {
        data: programmbs
      });
    case COURSE_VIEW_STUDENT:
      return Object.assign({}, state, {
        course_view: "student",
      });
    case COURSE_VIEW_EDITOR:
      return Object.assign({}, state, {
        course_view: "editor",
      });
    case CHANGE_PRINCIPLE_NAME_WARNING:
      return Object.assign({}, state, {
        change_principle_name_warning: true,
      });
    case CHANGE_UNIT_NAME_WARNING:
      return Object.assign({}, state, {
        change_unit_name_warning: true,
      });
    case CHANGE_SUCCESSFUL:
      return Object.assign({}, state, {
        change_unit_name_warning: false,
        change_principle_name_warning: false,
      });
    case ANSWER_LIST:
      return Object.assign({}, state, {
        answer_list: action.payload,
        loading: false
      });
    case SHOW_ANSWER:
      return Object.assign({}, state, {
        show_answer: action.payload,
      });
    case STOP_LIST:
      return Object.assign({}, state, {
        stop_list: action.payload,
        loading: false
      });
    case STOP_STATE_LIST:
      return Object.assign({}, state, {
        stop_state_list: action.payload,
        loading: false
      });
    case PROGRESS_STATE_LIST:
      return Object.assign({}, state, {
        progress_state_list: action.payload,
        loading: false
      });
    case PROGRESS_SHOW_NEXT_PENDING:
      return Object.assign({}, state, {
        progress_next_pending: action.payload,
      });
    case SHOW_PROGRESS_PENDING_CORRECTION:
      return Object.assign({}, state, {
        show_progress_pending_correction: action.payload,
        loading: false
      });
    case WAITING_PRINCIPLE_LOAD:
      return Object.assign({}, state, {
        waiting_principle_load: true,
      });
    case RESET_WAITING_PRINCIPLE_LOAD:
      return Object.assign({}, state, {
        waiting_principle_load: false,
      });
    case COURSE_PAYMENT:
      return Object.assign({}, state, {
        course_payment: action.payload,
      });
    case LIST_PAYMENT:
      return Object.assign({}, state, {
        list_payment: action.payload,
        loading: false
      });
    case SHOW_PAYMENT: 
      return Object.assign({}, state, {
        show_payment: action.payload,
      });
    case WAITING_STUDENTS_TRANSFER: 
      return Object.assign({}, state, {
        waiting_students_transfer: action.payload,
      });
    case SUCCESSFUL_TRANSFER_STUDENTS: 
      return Object.assign({}, state, {
        successful_transfer_students: action.payload,
      });
    case COURSE_PUBLISHED: 
      return Object.assign({}, state, {
        course_published: action.payload,
      });
    case THEREIS_COURSE_PUBLISHED: 
      return Object.assign({}, state, {
        thereis_course_published: action.payload,
      });
  }
  return state;
}